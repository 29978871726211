/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-globals */
import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import '../utils/default.css';
import Backend from '../utils/api.js';
import useTabs from '../utils/useTabs.jsx';
import SliderDisplay from '../Components/SliderDisplay/SliderDisplay.jsx';
// import DataViewer from '../Components/DataViewer/DataViewer.jsx';
import { SliderBranch } from '../Components/SliderBranch/SliderBranch.jsx';
import SliderMenu from '../Components/SliderMenu/SliderMenu';
import { MiniButton } from '../Components/MiniButton/MiniButton.jsx';
// import SliderManager from '../Components/SliderManager/SliderManager.jsx';
import PropTypes from 'prop-types';
import SliderForm from '../Components/SliderForm/SliderForm.jsx';
import SliderFormFilled from '../Components/SliderFormFilled/SliderFormFilled.jsx'
import Submission from '../Components/Submission/Submission.jsx';
import Slider from '../Components/Slider/Slider';
import DataTable from '../Components/DataTable/DataTable';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function Admin({ user }) {
   const [tabState, pushTab, revert] = useTabs();
   const [machineData, setMachineData] = useState({});
   const history = useHistory();

   const tabs = useMemo(() => [
      newProps => //0
         <Slider
            name="Which lab would you like to manage?"
            navColor="rgb(255, 238, 83)"
         >
            <SliderMenu
               placeholderText="Find a lab..."
               dataFunction={() => {

                    return Promise.all([
                        Backend.getLabPermissionsByUser(user.UID),
                        Backend.getLabs(1000)
                    ])
                    .then(function(responses) {
                        var lab_permission_res = responses[0];
                        var labs = responses[1];
                        
                        console.log(user);

                        if (user.userType > 0) {
                            return labs
                        }
                        else {


                            var lab_permission_ids = lab_permission_res.items.reduce( function(map, l) {
                                map[l.lab] = l
                                return map
                            }, {});
                            labs.items = labs.items.filter(l => l.id in lab_permission_ids && lab_permission_ids[l.id].accessLevel > 1);

                            
                            // console.log(lab_permission_res);
                            // console.log(lab_permission_ids)
                            // console.log(labs)


                            return labs;
                        }

                    });





               }}
               onClick={e => pushTab(tabs[1]({ ...newProps, id: e.id }))}
            />
            <MiniButton onClick={() => pushTab(tabs[6](newProps))}
               type = "add">
               <i className="fa fa-plus"></i>
            </MiniButton>
         </Slider>,
      newProps => //1
         <Slider
            name="What would you like to do?"
            navColor="rgb(226, 150, 90)"
         >

            <SliderBranch 
               entries={
                  [
                     {
                        image: "https://image.flaticon.com/icons/svg/1246/1246351.svg",
                        text: "Manage Users",
                        onClick: e => pushTab(tabs[2](newProps))
                     },
                     {
                        image: "https://image.flaticon.com/icons/png/512/9/9156.png",
                        text: "Manage Machines",
                        onClick: e => pushTab(tabs[3](newProps))
                     },
                     {
                        image:"https://cdn4.iconfinder.com/data/icons/business-267/48/40_financial_year_growth_report_analytics_chart_graph-512.png",
                        text:"View Reports",
                        onClick: e => pushTab(tabs[5](newProps))
                     },
                     {
                        image:"https://cdn-icons.flaticon.com/png/512/4530/premium/4530725.png?token=exp=1644956375~hmac=eddffc3e034ff95d7d41048b9a555e92",
                        text:"Top Users",
                        onClick: e => history.push(
                           {  pathname: `/topusers`,
                              state: {userId: user.UID, labId: newProps.id}
                           })
                     }
                  ]
               }
            />
            <MiniButton onClick={() => pushTab(tabs[9](newProps))}
               type = "delete">
               <i class="fa fa-trash-o" aria-hidden="true"></i>
            </MiniButton>
         </Slider>,
      ({ id, deleted }) => //2
         <Slider
            name="User Details"
            navColor="rgb(50, 157, 237)"
         >
            <DataTable
               title="Users"
               columns={[
                  { title: 'User UID', field: 'user', editable: 'onAdd' },
                  { title: 'accessLevel', field: 'accessLevel' },
               ]}
               dataFunction={() => Backend.getPermissionsByLab(user.UID, id)}
               error={"Action failed: The UID you provided was likely not found in the MSM database. Register the user" +
                  " with the MSM before adding them to a lab."}
               //Attempt to update the data in the backend
               submitRowChange={(newPermission, oldPermission) => Backend.updateLabPermission(user.UID, oldPermission.accessID, newPermission.accessLevel)}
               submitNewRow={(newPermission) => Backend.newLabPermission(user.UID, newPermission.accessLevel, newPermission.user, id)}
            >
            </DataTable>
            <h5><center><i> An access level that is <b>less than or equal to one</b> means they are a <b>user</b> and will be limited to signing in. </i></center></h5>
            <h5><center><i> An access level that is <b>greater than one</b> means they are a <b>manager</b> and will have full admin access to the lab. </i></center></h5>
         </Slider>,
      newProps => //3
         <Slider
            name="Which machine would you like to manage?"
            navColor="rgb(70, 200, 90)"
         >
            <SliderMenu
               placeholderText="Search for a machine..."
               dataFunction={() => Backend.getMachineByLab(user.UID, newProps.id)}
               onClick={props => pushTab(tabs[4]({ id: props.id, lab: newProps.id }))}
            />
            <MiniButton onClick={() =>
               pushTab(tabs[8](newProps))
            }>
               <i className="fa fa-plus"></i>
            </MiniButton>
         </Slider>,
      ({ id, lab }) => //4
         <Slider
            name="Machine Details"
            navColor="rgb(200, 170, 190)"
         >
            <SliderFormFilled
               title="Register"
               dataFunction={() => Backend.getMachineById(user.UID, id)}
               fields={[
                  // {
                  //    key: "id",
                  //    placeholder: "Machine ID",
                  //    disabled: true
                  // },
                  {
                     key: "name",
                     placeholder: "Machine Name",
                     validate: /^[A-Za-z0-9]+(?:\s+[A-Za-z0-9]+)*$/,
                     errorText: "Must be at least one word and have no leading or trailing spaces."
                  }
                  // },
                  // {
                  //    key: "category",
                  //    placeholder: "Tool Category",
                  //    validate: "",
                  //    errorText: "",
                  // },
                  // {
                  //    key: "restricted",
                  //    placeholder: "Restriction Level",
                  //    validate: "",
                  //    errorText: "",
                  //    disabled: true
                  // },
               ]}
               onSubmit={(x) => {
                  let args = {};
                  args['machineType'] = x.name;
                  args['lab'] = lab;

                  Backend.editMachine(user.UID, id, args)
                     .then((res) => {
                        pushTab(tabs[7]({ success: res.status === 201, index: 3 }))
                     });
               }}
            />
         </Slider>,
      ({ id }) => //5
         <Slider
            name="Lab Requests"
            navColor="rgb(70, 200, 90)"
         >
            <DataTable
               onExport={() => Backend.downloadLabRequests(user.UID, id)}
            ></DataTable>
         </Slider>,
      newProps => //6
         <Slider
            name="New Lab Info"
            navColor="rgb(255, 238, 83)"
         >
            <SliderForm
               title="Register"
               fields={[
                  {
                     key: "name",
                     placeholder: "Name (e.g. Iribe Sandbox)",
                     validate: /^[A-Za-z0-9]+(?:\s+[A-Za-z0-9]+)*$/,
                     errorText: "Must be at least one word and have no leading or trailing spaces."
                  },
                  {
                     key: "id",
                     placeholder: "Abbreviation (e.g. MIC)",
                     validate: /^[A-Z0-9]{3}$/,
                     errorText: "Must be three capital letters or numbers, with no leading or trailing spaces"
                  },
               ]}
               onSubmit={(x) => {
                  // Check abbreviation not already taken
                  Backend.getLabById({id: x.id, UID: user.UID}).then((res) => {
                     if (res.status === 200) {
                        alert(`Lab Abbreviation ${x.id} already used. Please pick something different.`);   
                     } else {
                        Backend.newLab(
                           { ...x, UID: user.UID }
                        )
                           .then((res) =>
                              pushTab(tabs[7]({ success: res.status === 201, index: 1 }))
                           );
                     }
                  });
               }}
            />
         </Slider>,
      ({ success, index }) => //7
         <Slider
            name="Submission"//TODO fill entire screen with submission div
            navColor="rgb(226, 150, 90)"
         >
            <Submission
               success={success}
               onClick={() => revert(index)}
            />
         </Slider>,
      newProps => //8
         <Slider
            name="New Machine Info"
            navColor="rgb(70, 200, 90)"
         >
            <SliderForm
               title="Register"
               fields={[
                  {
                     key: "name",
                     placeholder: "Machine Name",
                     validate: /^[A-Za-z0-9]+(?:\s+[A-Za-z0-9]+)*$/,
                     errorText: "Must be at least one word and have no leading or trailing spaces."
                  },
                  {
                     key: "restricted",
                     placeholder: "Restriction Level (default 0)",
                     defaultValue: 0
                  }, // ???????
               ]}
               onSubmit={(x) => {
                  Backend.newMachine({
                     ...x,
                     UID: user.UID,
                     lab: newProps.id
                  })
                     .then((res) => {
                        pushTab(tabs[7]({ success: res.status === 201, index: 3 }))
                     }
                     );
               }}
            />
         </Slider>,
         newProps => //9
         <Slider
            name="Deleting Lab"
            navColor="rgb(255, 238, 83)"
         >
            <h1> Deleted labs cannot be recovered.<br></br>Type confirm to verify you'd like to delete this lab. </h1>
            <SliderForm
               title="Delete"
               fields={[
                  {
                     key: "name",
                     placeholder: "Type \"confirm\" to delete lab",
                     validate: /^[A-Za-z0-9]+(?:\s+[A-Za-z0-9]+)*$/,
                     errorText: "You must type \"confirm\" to delete a lab."
                  },
               ]}
               onSubmit={(x) => {
                  if (x.name == "confirm") {
                     Backend.toggleLab(
                     {id:newProps.id, UID:newProps.user.UID, disable:true}
                     )
                     .then((res) =>
                        pushTab(tabs[7]({ success: res.status === 201, index: 1 }))
                     );
                  } else{
                     alert("Please enter \"confirm\" to delete lab or hit back to cancel")
                  }
                  
               }}
               deleteForm={true}
            />
         </Slider>,
   ], [user.UID, pushTab, revert]);

   useEffect(() =>
      pushTab(tabs[0]({user:user})),
       [user, tabs, pushTab]);
   

   return (
      <SliderDisplay>
         {tabState.tabs}
      </SliderDisplay>
   );


}
Admin.propTypes = {
   user: PropTypes.shape({
      UID: PropTypes.number.isRequired
   }).isRequired
};

