//api hook prototype
const apiHook = {
   setURL(URL) {
      this.baseURL = URL;
   },

   /// Send an API call to the backend
    /// - path: The directory to append to the base URL
    /// - method: The requesting method- explicitly GET or POST
    /// - params: The keys and values to attach to this request
    /// - accept: Which response type is expected / accepted, set to JSON by default
   apiCall(path, method, params, accept='application/json') {
      // Construct a full URL object from the concatenation of the two URL strings
      var fullURL = new URL(this.baseURL + path);
      // Represent the provided keys and values in an agreeable format
      fullURL.search = new URLSearchParams(params).toString();
      // Return the result of this fetch
      return fetch(fullURL, {
         method: method,
         headers: {
            'Accept': accept,
            "Access-Control-Allow-Origin": "*"
         }
      })
      // Once a response is returned
      .then(response => {
         // If the response type was in CSV format
         if (accept==='text/csv'){
            return response.blob()
            .then(blob => {
               let content = response.headers.get('content-disposition')
               let filename = content.substr(
                  content.indexOf('filename=') + 9,
                  content.indexOf('.csv')
               )
               const element = document.createElement("a");
               element.href = URL.createObjectURL(blob);
               element.download = filename || 'download.csv';
               document.body.appendChild(element); // Required for this to work in FireFox
               element.click();
            });
         }
         // If the response type was in JSON format
         else {
            return (
               // Convert the response to an Object
               response.json()
               // When the conversion completes
               .then(responseJSON => 
                  // Return the status and JSON data as an object
                  ({status: response.status, ...responseJSON.data})
               )
            )
         }
      });
   },

   /// Download all pending requests
   /// - UID: The University ID of the User viewing the requests
   downloadAllRequests(UID){
      return this.apiCall("/requests/",'GET',{UID: UID},'text/csv')
   },

   /// Download all pending requests in a given Lab
   /// - UID: The University ID of the User viewing the requests
   /// - labID: The unique identifier of the Lab in question
   downloadLabRequests(UID, labID){
      return this.apiCall(`/labs/${labID}/requests`,'GET',{UID: UID},'text/csv')
   },

   /// Download all pending requests for a given Machine
   /// - UID: The University ID of the User viewing the requests
   /// - machineID: The unique identifier of the Machine in question
   downloadMachineRequests(UID, machineID){
      return this.apiCall(`/machines/${machineID}/requests`,'GET',{UID: UID},'text/csv')
   },

   /* REQUESTS */
   /// Creates a new Request for a given machine
   /// - UID: The University ID associated with this User
   /// - machineId: The identifier for the requested machine
   /// - reason: User's reason for requesting this machine permission
   newRequest({UID, machineId, reason}) {
      return this.apiCall("/requests/", 'POST', {
         UID: UID,
         machine: machineId,
         reason: reason
      });
   },

   /* USERS */
   /// Obtains an array of Users
   /// - UID: The University ID associated with this User
   /// - num: The maximum number of Users to fetch
   getUsers(UID, num) {
      // TODO: Add the ability to specifify page number to allow frontend pagination
      return this.apiCall("/users/", 'GET', {UID: UID, per_page: num});
   },

   /// Obtains a singular user
   /// - UID: The University ID associated with this User
   getUser(UID) {
      /* 
          This is only functional for Users requesting their own document.
          In the future we may want to allow for the requesting of a different resource 
          than the User's own document. Lab Managers may want to see the details of a single
          User in much the same way as in getUsers.
       */
      return this.apiCall(`/users/${UID}`, 'GET', {UID: UID})
   },

   /// Takes a swipenum, calls backend to convert swipeNum to UID. Backend calls
   /// University api to convert swipenum to uid. 
   /// - swipeNum: swipeNumber of user
   convertSwipeNumToUID(swipeNum){
      return this.apiCall(`/users/swipenum_convert/${swipeNum}`, 'GET')
   },

   /// Creates a new User in the database
   /// - UID: The University ID associated with this User
   /// - directory
   /// - directoryID: Directory ID
   /// - firstName: User's first name
   /// - lastName: User's last name
   /// - email: User's email
   /// - userType: Integer representing whether the user is a student, lab manager, etc.
   /// - The swipe number associated with this user- REDUNDANT
   newUser({UID, directoryID, firstName, lastName, email, userType = 0, swipeNum}) {
      // At least for now, the functionality is identical to editUser
      arguments[0]['userType'] || (arguments[0]['userType'] = 0);
      return this.editUser(arguments[0]);
   },

   /// Edits the information associated with a User
   /// Fields are identical to newUser except that userType has no default value
   editUser({UID, directoryID, firstName, lastName, email, userType, swipeNum}) {
      return this.apiCall(`/users/${UID}`, 'PUT',
         {
            swipeNum: swipeNum,
            directoryID: directoryID,
            firstName: firstName,
            lastName: lastName,
            email: email,
            userType: userType,
         }
      );
   },

   /// Retrieves all lab permissions associated with a User
   /// - UID: The University ID of the User in question
   getLabPermissionsByUser(UID) {
      return this.apiCall(`/users/${UID}/lab_permissions`, 'GET', {UID: UID});
   },

   /// Retrieves all pending permission requests associated with a User
   /// - UID: The University ID of the User in question
   getRequestsByUser(UID) {
      return this.apiCall(`/users/${UID}/requests`, 'GET', {UID: UID});
   },

   /// Retrieves pending permission requests in a given lab for a given User
   /// - UID: The University ID of the User viewing the requests
   /// - Lab: The unique identifier of the Lab in question
   getRequestsByLab(UID, Lab) {
      return this.apiCall(`/labs/${Lab}/requests`, 'GET', UID ? {UID: UID}:{});
   },

   /// Retrieves granted machine permissions for a given User
   /// - UID: The University ID of the User in question
   getMachinePermissionsByUser(UID) {
      return this.apiCall(`/users/${UID}/machine_permissions`, 'GET', {UID: UID});
   },

   /* LABS */
   /// Retrieve an array of Labs
   /// - num: The quantity of lab objects to retrieve
   getLabs(num) {
      // TODO: Add page number to allow for frontend pagination
      return this.apiCall("/labs/", 'GET', {"per_page": num});
   },

   /// Retrieve a singular Lab object
   /// - id: The unique identifier associated with this Lab
   getLabById({id, UID}) {
      return this.apiCall(`/labs/${id}`, 'GET', {UID: UID});
   },

   /// Creates a new Lab
   /// - UID: The University ID of the User trying to create the Lab
   /// - id: The unique identifier to assign to the new Lab
   /// - name: THe name of the new Lab
   newLab({UID, id, name}) {
      return this.apiCall(`/labs/${id}`, 'PUT', {labName: name, UID: UID});
   },

   /// Edits a Lab's information
   /// - id: The unique identifier by which to query the Lab
   /// - name: The new name for the Lab
   editLab(id, name) {
      return this.apiCall(`/labs/${id}`, 'PUT', {labName: name});
   },

   /// Toggle the availability of a Lab
   /// - id: The unique identifier of the Lab in question
   /// - disable: Whether to disable this Lab (true) or enable it (false)
   toggleLab({id, UID, disable}) {
      console.log("Setting lab to ", disable);
      return this.getLabById({id:id, UID:UID}).then((res) => {
         // TODO: Change the API call to use PATCH when it is available
         //return this.apiCall("/labs/" + id, 'PUT', {...res, deleted: disable});
         return this.apiCall(`/labs/${id}`, 'PUT', {
            UID: UID,
            labName: res.name,
            deleted: disable
         });
      });
   },

   /// Retrieves Machines stored in a given Lab
   /// - UID: The University ID of the User viewing the Machines
   /// - id: The unique identifier of the Lab in question
   getMachineByLab(UID, id) {
      return this.apiCall(`/labs/${id}/machines`, 'GET', {UID: UID});
   },

   /// Retrieves Permissions associated with a given Lab
   /// - UID: The University ID of the User viewing the permissions
   /// - id: The unique identifier of the Lab in question
   getPermissionsByLab(UID, id) {
      return this.apiCall(`/labs/${id}/permissions`, 'GET', {UID:UID});
   },

   /*
   Updates the lab permission
   UID: The University ID of the User viewing the permissions
   id: identifier of the lab permission object
   accessLevel: the new access level
   */
   updateLabPermission(UID, id, accessLevel) {
      return this.apiCall(`/lab_permissions/${id}`, 'PATCH', {UID:UID, accessLevel:accessLevel})
   },

   /*
   Creates new lab permission
   UID: The University ID of the User viewing the permissions
   accessLevel: the new access level
   user: id for the user
   lab: id for the lab
   */
   newLabPermission(UID, accessLevel, user, lab) {
      return this.apiCall(`/lab_permissions/`, 'POST', {
         UID: UID,
         accessLevel: accessLevel,
         user: user,
         lab: lab
      });
   },

   /* MACHINES */
   /// Retrieves an individual Machine
   /// - id: The unique identifier of the Machine in question
   getMachineById(UID, id) {
      return this.apiCall(`/machines/${id}`, 'GET', {UID:UID});
   },

   /// Creates a new Machine
   /// - UID: The University ID of the User creating the Machine
   /// - name: The name of the Machine
   /// - category: The type of the Machine
   /// - restricted: The availability of this Machine
   /// - lab: The unique identifier of the Machine's Lab
   newMachine({UID, name, restricted, lab}) {
      return this.apiCall("/machines/", 'POST', {
         UID: UID,
         machineType: name,
         restricted: restricted, 
         lab: lab
      });
   },

   /// Edits the information associated with a Machine. The following keys
   /// can be passed in args (with their respective values) to update a machine
   /// - id: The unique identifier of the Machine in question
   /// - machineType: The new name of the Machine
   /// - categoryID: New category of machine
   /// - restricted: New restriction of machine
   /// - lab: New lab of machine
   /// - sublabID: New sublab of machine
   /// - deleted: Update deletion state
   /// - quizID: New quizID of machine
   /// - machineStatus: New Status of machine
   editMachine(UID, id, args) {
         return this.apiCall("/machines/" + id , 'PATCH', {
            UID: UID,
            ...args
         });
   },

   /// Toggle the availability of a Machine
   /// - id: The unique identifier of the Machine in question
   /// - disable: Whether to disable this Machine (true) or enable it (false)
   toggleMachine(id, disable) {
      console.log("Setting machine to ", disable);
      return this.getMachineById(id, res => {
         // TODO: Change the API call to use PATCH when it is available
         //return this.apiCall("/machines/" + id, 'PUT', {...res, deleted: disable});
         return this.apiCall("/machines/" + id, 'PUT', {
            machineType: res.data.name,
            category: "Test category",
            restricted: res.data.restricted,
            lab: res.data.labdId,
            deleted: disable
         });
      });
   },

   /// Enable a Machine
   /// - id: The unique identifier of the Machine in question
   disableMachine(id) {
      return this.toggleMachine(id, true);
   },

   /// Disable a Machine
   /// - id: The unique identifier of the Machine in question
   enableMachine(id) {
      return this.toggleMachine(id, false);
   },
}

// See https://create-react-app.dev/docs/adding-custom-environment-variables/ for an explanation of the global environment variable system
// This allows switching between base URLs depending on whether we run `npm start` or `npm run-script build`
// Will break deployments if urls change.
const Backend = Object.create(apiHook);

// Set the base URL for the API
Backend.setURL((
   // If the current tab's URL origin points to production, use production backend.
   (new URL(window.location.href).origin === "https://msm.make.umd.edu") ? "https://msm.make.umd.edu"
      // If the current tab's URL origin points to stage, use stage backend.
      : (new URL(window.location.href).origin === "https://stage.msm.make.umd.edu") ? "https://stage.msm.make.umd.edu"
      // Otherwise, use localhost.
      : "http://localhost:3001"
   ) +
   // Append '/api' to our hostname no matter the origin
   "/api/v1"
)

// Expose the Backend object to other files
export default Backend;


