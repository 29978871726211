import React, { useState } from 'react';
import SliderMenu from '../SliderMenu/SliderMenu';
import { MiniButton } from '../MiniButton/MiniButton.jsx';

export default function SliderMenuMulti({placeholderText, submitSelection, dataFunction, permissionFunction, checkPermissionFunction}){
   const [selected, setSelected] = useState([]);
   
   return (       
         <>
            <SliderMenu 
               placeholderText={placeholderText}
               dataFunction={dataFunction}
               onClick={e => {
                  let newSelection = selected.slice();
                  const ind = newSelection.indexOf(e);
                  if (ind > -1){
                     newSelection = newSelection.slice(0, ind).concat(newSelection.slice(ind + 1));
                  } else {
                     newSelection = newSelection.concat(e);
                  }
                  setSelected(newSelection);
               }}
               selected={selected}
               permissionFunction={permissionFunction}
               checkPermissionFunction={checkPermissionFunction}
            /> 
            {selected.length !== 0 &&
               <MiniButton onClick={() => submitSelection({selected:selected})}>
                  <i className="fa fa-arrow-right"></i>
               </MiniButton>
            }
         </>
   );
}
