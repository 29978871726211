/* eslint-disable no-restricted-globals */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App.jsx';

// const user = {
//       "status": 200,
//       "id": 4,
//       "swipeNum": 8715,
//       "UID": 124352,
//       "directoryID": "Test",
//       "firstName": "TestLiam",
//       "lastName": "Test",
//       "email": "lol",
//       "userType": 9001
//    }

ReactDOM.render(<App />, document.getElementById('app'));
