import React, { useState, useEffect } from 'react';
import './SliderMenu.css';
import {
   TextField
} from '@material-ui/core';

// This represents a list of different options to choose from, such as labs or machines
// permissionFunction and checkPermissionFunction are optional parameters used to gray out unpermitted options
export default function SliderMenu({placeholderText, dataFunction, selected, onClick, toggle, permissionFunction, checkPermissionFunction}) {
   const [filterText, setFilterText] = useState('');
   const [data, setData] = useState([]);
   const [permissions, setPermissions] = useState(null)

   // Selectable data items
   const [selectableData, setSelectableData] = useState([]);
   // Unselectable (grayed out) data items
   const [unselectableData, setUnselectableData] = useState([]);

   useEffect(() => {
      dataFunction()
      .then(({items, status}) => {
         console.log(status);
         console.log(items);
         if (status === 200){
            setData(items);
         } else {
            alert("server call failed!");
         }
      });

      if (permissionFunction !== undefined) {
         permissionFunction().then(({items, status}) => {
            if (status == 200) {
               setPermissions(items);
            } else {
               alert("server call failed");
            }
         })
      }
   }, [dataFunction, toggle, permissionFunction]);


   // Set selectable and unselectable data
   useEffect(() => {
      var selectable = []
      var unselectable = []
      if (checkPermissionFunction === undefined) {
         selectable = data;
      }
      else {
         data.map(e => {
            if (checkPermissionFunction(permissions, e)) {
               selectable.push(e);
            } else {
               unselectable.push(e);
            }
         });
      }

      setSelectableData(selectable);
      setUnselectableData(unselectable);
   }, [data, permissions])


   const changeFilter = e => { setFilterText(e.target.value.toLowerCase()) };

   return (
      <div className="SliderMenu">
         <div className="SliderInput">
            <TextField
               autoComplete='off'
               size='small'
               variant='filled'
               fullWidth
               margin='normal'
               label={placeholderText}
               value={filterText}
               onChange={changeFilter}
            />
         </div>
         <div className="SliderContent">
            <ul>{
               // Items the user has permissions for
               selectableData.filter(datum =>
                  datum.name.toLowerCase().includes(filterText)
               ).map((e) => {
                  const selectedEntry = selected && selected.includes(e);
                  return (
                     <SliderMenuEntry
                        key={e.name}
                        selected={selectedEntry}
                        onClick={() => onClick(e)}
                        name={e.name}
                        selectable={true}
                     />);
               })}
               {// Items the user doesn't have permissions for
                  unselectableData.filter(datum =>
                     datum.name.toLowerCase().includes(filterText)
                  ).map((e) => {
                     const selectedEntry = selected && selected.includes(e);
                     return (
                        <SliderMenuEntry
                           key={e.name}
                           selected={selectedEntry}
                           onClick={null}
                           name={e.name}
                           selectable={false}
                        />);
                  })}
            </ul>
         </div>
      </div>
   )
}

function SliderMenuEntry(props) {
   return (
      <li style={props.style}>
         <div
         onClick={props.onClick} 
         className={`SliderMenuEntry ${props.selected ? 'selected':''} ${props.selectable ? '' : 'unselectable'}`}
         selectable={props.selectable ? "selectable" : "unselectable"}
         >
            {props.name}
         </div>
      </li>
   );
}
