import React from 'react';
import { useEffect, useMemo } from 'react';
import useTabs from '../utils/useTabs.jsx';
import PropTypes from 'prop-types';
import Backend from '../utils/api.js';
import { withRouter } from "react-router-dom";

import SliderMenuMulti from '../Components/SliderMenuMulti/SliderMenuMulti.jsx';
import SliderDisplay from '../Components/SliderDisplay/SliderDisplay.jsx';
import DropDownMenu from '../Components/DropDownMenu/DropDownMenu.jsx';
import Submission from '../Components/Submission/Submission.jsx';
import '../utils/default.css';
import Slider from '../Components/Slider/Slider';


function User({user, lab, history}){
   const [tabState, pushTab] = useTabs();

   const tabs = useMemo(() => [
      newProps => //0
         <Slider
         name="Machine Selection"
         navColor="rgb(224,58,62, .9)"
         contentTitle="Which machine do you want to use?"
         >
            <SliderMenuMulti
               placeholderText="Find a machine...!"
               dataFunction={() => Backend.getMachineByLab(user.UID, lab.id)}
               submitSelection={(s) => pushTab(tabs[1]({...s, ...newProps}))}
               permissionFunction={() => Backend.getMachinePermissionsByUser(user.UID)}
               checkPermissionFunction={(permissions, e) => {
                  var byPassPermission = true; //true bypasses permission for user, false function runs as intended
                  if (byPassPermission === false) {
                     if (permissions === null) {
                        return true;
                     }
                     var arrayLength = permissions.length;
                     for (var i = 0; i < arrayLength; i++) {
                        if (permissions[i].machine === e.id) {
                           return true;
                        }
                     }
                     return false;
                  }
                  return true; 
               }}
            />
         </Slider>,
      ({selected}) =>   //1
         <Slider
            name="Reason Submission"
            navColor="rgb(224,58,62, .8)"
            contentTitle="What are you using this for?"
         >
            <DropDownMenu 
               title="Reason"
               fields={[
                  "Research Project", 
                  "Student Project", 
                  "Personal Project",
                  "Start-up",
                  "Workshop",
               ]}
               onSubmit={(x) => { // TODO: figure out batch operation for backend and check failure
                  selected.forEach(machine => {
                     Backend.newRequest({
                        UID: user.UID,
                        machineId: machine.id,
                        reason:x
                     })
                  });
                  pushTab(tabs[2]({success: true}))
               }}
            />
         </Slider>,
      ({success}) => //2
         <Slider
            name="Submission" // TODO: fill entire screen with submission div
            navColor="rgb(224,58,62, .7)"
            >
            <Submission 
               success={success}
               onClick={() => history.push('/')}
            />
         </Slider>
   ], [lab, pushTab, user, history]);

   useEffect(() =>
      pushTab(tabs[0]({user:user, lab:lab}))
   , [user, tabs, pushTab, lab]);
   
   return (
      <SliderDisplay>
         {tabState.tabs}
      </SliderDisplay>
   );
}
User.propTypes = {
   user: PropTypes.shape({
      UID: PropTypes.number.isRequired
   }).isRequired,
   lab: PropTypes.shape({
      id: PropTypes.string.isRequired
   }).isRequired
};

export default withRouter(User);