import React from 'react';
import '../utils/default.css';
import Login from '../Login/Login.jsx';
import User from '../User/User';
import Admin from '../Admin/Admin.jsx';
import RegisterForm from '../RegisterForm/RegisterForm.jsx';
import TopUsers from '../Admin/TopUsers.jsx';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Redirect
} from "react-router-dom";
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
   palette: {
      primary: red,
   },
   typography: {
      fontSize: 20,
   },
   // props: {
   //    MuiTypography: {
   //       variantMapping: {
   //          h1: 'h2',
   //          h2: 'h2',
   //          h3: 'h2',
   //          h4: 'h2',
   //          h5: 'h2',
   //          h6: 'h2',
   //          subtitle1: 'h2',
   //          subtitle2: 'h2',
   //          body1: 'span',
   //          body2: 'span',
   //       },
   //    },
   // },
});
   

class App extends React.Component {
   constructor(props) {
      super(props);
      var lab;
      try {
         lab = JSON.parse(localStorage.getItem("lab"));
      }
      catch(err) {
         lab = {};
      }
      this.state = {
         user: {},
         lab: lab
      };
      window.onpopstate = this.backButton;
      
      // var time;
      // var resetTimer = () => {
      //    clearTimeout(time);
      //    time = setTimeout(this.logout, 2 * 60 * 1000); // Wait two minutes before timing out
      // }
      // window.onload = document.onmousemove = document.onkeypress = resetTimer;
   }


   login = (e) => {
      this.setState({user: e});
   }

   logout = () => {
      if (this.state.user) {
         this.setState({
            user:{}
         });
      }
      window.location='/';
   }


   setLab = (lab) => {
      localStorage.setItem("lab", JSON.stringify(lab));
      this.setState({lab:lab});
   }
   // TODO: make this faster
   /*
      Note that the React router redirect requires that the S3 repo's error document is set to `index.html`,
      as discussed in the answer at https://stackoverflow.com/a/52343542/5732397
   */
   render() {
      const user = this.state.user;
      const lab = this.state.lab;

      return (
         <ThemeProvider theme={theme}>
         <Router>
            <Switch>
               <Route exact path="/">
                  <Login 
                     onSubmit={e => this.setState({user:e})}
                     currLab={lab}
                     setCurrLab={this.setLab}
                     routeFunc={(user_res, lab_permission_res) =>{
                        if (user_res.status===200){
                           if (user_res.userType || lab_permission_res.items.some(ele => ele.lab === lab.id)){
                              return '/admin';
                           } else {
                              return '/user';
                           }
                        } else {
                           return '/register';
                        }
                     }}
                     />
               </Route>
               <Route path="/user"
                  render={props => 
                     user.UID === undefined ? <Redirect to="/" /> : <User user={user} lab={lab}/>}
               />
               <Route path="/admin"
                  render={props => (user.UID === undefined ? <Redirect to="/" /> : <Admin user={user} />)}
               />
               <Route path="/topusers"
                  render={props => <TopUsers />}
               />
               <Route path="/register">
                  <RegisterForm swipeNum={user.id || '-1'} />
               </Route>
            </Switch>
         </Router>
         </ThemeProvider>
      );
   }
}


export default App