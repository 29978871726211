import React from 'react';
import { useReducer, useMemo } from 'react'

function tabReducer(tabState, dispatch){
   const toggle = tabState.toggle;
   const length = tabState.length;
   switch (dispatch.type){
      case 'push':
         return {
            length: length + 1,
            tabs:[    
               ...tabState.tabs, 
               React.cloneElement(dispatch.tab, 
               {
                  index: length, 
                  toggle: toggle,
                  key: length,
                  slide: true
               })
            ]
         };
      case 'revert':
         return {
            length: dispatch.index,
            tabs: 
               tabState.tabs.map((slider, index) =>
                  React.cloneElement(slider, {
                     slide: (index < dispatch.index) ? true : false,
                     toggle: (slider.props.index===dispatch.index - 1) ? !slider.props.toggle:slider.props.toggle
                  })
               )
         };
      case 'delete':
         return {
            length: dispatch.index,
            tabs: tabState.tabs.slice(0, dispatch.index)
         };
      default:
         return tabState;
   };
}

export default function useTabs(){
   const [tabState, dispatch] = useReducer(tabReducer, {length:0, tabs:[]});
   
   const revert = useMemo(() => 
      (x) => {
         dispatch({
            type:'revert',
            index:x
         });
         setTimeout(() => 
            dispatch({
               type:'delete',
               index:x
            })
         , 200);
   },[]);

   const pushTab = useMemo(() => 
      (tab) => {
         dispatch({
            type:'push', 
            tab: React.cloneElement(tab, {
               onClick: e => {
                  const index = parseInt(e.target.getAttribute("index")) + 1;
                  revert(index);
               }
            })
         });
      }
   ,[revert]);

   return [tabState, pushTab, revert];
}