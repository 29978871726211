import React from 'react';
import '../../utils/default.css';

function SliderDisplay({tabQueue, children}){
   return (
      <div className="appContainer">
         <div className="sliderContainer">
            {children}
         </div>
      </div>
   );
}

export default SliderDisplay;