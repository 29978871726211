import React from 'react';
import './SliderBranch.css';

function SliderBranch(props) {
   return (
      <div className="SliderBranch">
         {props.entries.map((e) => <SliderBranchEntry   key={e.text} {...e}/>)}
      </div>
   );
}

function SliderBranchEntry(props) {
   return (
      <div onClick={props.onClick} className="SliderBranchChoice">
            <img src={props.image} alt={props.text}></img>
            <span>{props.text}</span>
      </div>
   );
}

export {SliderBranch};