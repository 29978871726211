import React from 'react';
import { useEffect, useMemo } from 'react';
import useTabs from '../utils/useTabs.jsx';
import Backend from '../utils/api.js';
import '../utils/default.css';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom"

import SliderDisplay from '../Components/SliderDisplay/SliderDisplay.jsx';
import SliderForm from '../Components/SliderForm/SliderForm.jsx';
import Submission from '../Components/Submission/Submission.jsx';
import Slider from '../Components/Slider/Slider';
import User from '../User/User.jsx';

export default function RegisterForm({swipeNum}){
   const [tabState, pushTab] = useTabs();
   let history = useHistory();

   //If userID passed is valid, autofill. If not, don't autofill
   function validateAutofillUID(uid){
      let uid_s = String(uid);
      
      const re_uid = /^[0-9]{9}$/
      if (uid_s.match(re_uid)){
         return uid_s;
      } else {
         return;
      }
   }

   const tabs = useMemo(() => [
      newProps => //0
         <Slider
            name="Register?"
            navColor="rgb(205, 205, 205)"
         >
            <SliderForm
               title="Register"
               fields={[
                  {key:"firstName", 
                  placeholder:"First Name", 
                  validate:/^[0-9A-Za-z\s-]+$/, 
                  errorText:"May only contain letters, numbers, spaces, and hyphens."
                  },
                  {key:"lastName", 
                  placeholder:"Last Name", 
                  validate:/^[0-9A-Za-z\s-]+$/, 
                  errorText:"May only contain letters, numbers, spaces, and hyphens."
                  },
                  {key:"UID", 
                  placeholder:"UID",
                  defaultValue: validateAutofillUID(swipeNum),
                  validate:/^\d{9}$/, 
                  errorText:"Invalid UID. Must be 9 digits."
                  },
                  {key:"directoryID", 
                  placeholder:"Directory ID", 
                  validate:"", 
                  errorText:""
                  },
                  {key:"email", 
                  placeholder:"Email Address", 
                  validate:/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 
                  errorText:"Invalid email address."
                  },
               ]}
               onSubmit={(x) => {
                  Backend.newUser({...x, swipeNum: swipeNum})
                  .then((res) => 
                     pushTab(tabs[1]({success: res.status===201})
                  ));
               }}
            />
         </Slider>,
      ({success}) => //1
         <Slider
            name="Submission" //TODO fill entire screen with submission div
            navColor="rgb(226, 150, 90)"
         >
            <Submission 
               success={success}
               link='/'
               onClick={() => history.push('/')}
            />
         </Slider>
   ], [pushTab, swipeNum]);
   
   useEffect(() =>
      pushTab(tabs[0]())
   , [tabs, pushTab]);
   
   return (
      <SliderDisplay>
         {tabState.tabs}
      </SliderDisplay>
   );
}
RegisterForm.propTypes = {
   swipeNum: PropTypes.string.isRequired
};