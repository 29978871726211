import React from 'react';
import {withRouter} from "react-router-dom";

function Submission({success, onClick}){
   return (
      <div 
         style={{
            textAlign: 'center',
            width:'100%',
         }}
         onClick={onClick}
         >
         <br/>
         {success ? 
            <>
               <div style={{ fontSize: '1.2em' }}>Success!</div>
               <i style={{color: "green", fontSize: "3em"}} 
                  className="fa fa-check-circle"></i>
               <div>Click to continue</div>
            </>
         :
            <>
               <div style={{ fontSize: '1.2em' }}>Database Failure!</div>
               <i 
                  style={{
                     color: "red", fontSize: "3em", width:'100%'
                  }
                  } 
                  className="fa fa-times-circle"></i>
               <div>Click to continue</div>
            </>
         }

      </div>
   );
}

export default withRouter(Submission)