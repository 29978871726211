import React, { useState, useEffect } from 'react';
import './SliderFormFilled.css';
import TextField from '@material-ui/core/TextField';
import SliderForm from '../SliderForm/SliderForm';

export default function SliderFormFilled({dataFunction, fields, onSubmit}) {
   const [data, setData] = useState({});
   const [newFields, setNewFields] = useState(fields)
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      dataFunction()
      .then((res) => {
         if (res.status === 200) {
            setData({...res});
         } else {
            alert("server call failed!");
         }
      });
      newFields.map((fieldMap) => {
         return { ...fieldMap};
      })
   }, [dataFunction])

   useEffect(() => {
      if (Object.keys(data).length !== 0) {
         setNewFields(fields.map((fieldMap) => {
            return { ...fieldMap, defaultValue: data[fieldMap["key"]] };
         }));

         setLoading(false);
      }      
   }, [data])

   // Show nothing if loading
   const getForm = () => {
      if (loading) {
         return null;
      }
      else {
         return <SliderForm 
         fields={newFields}
         onSubmit={onSubmit}
      />
      }
   }

   return getForm();
}