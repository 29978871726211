import React, { useState, useEffect } from 'react';
import Backend from '../utils/api.js';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import SettingsIcon from '@material-ui/icons/Settings';
import {
   makeStyles,
   Drawer,
   Box, 
   Grid, 
   Typography, 
   TextField,
   Select,
   MenuItem,
   FormControl,
   IconButton,
   InputLabel
} from '@material-ui/core';

const useStyles = makeStyles(() => (
   {
      root: {
         flexGrow: 1,
      }
   }
));

function Login({onSubmit, routeFunc, history, currLab, setCurrLab}){
   const [settingsOpen, setSettingsOpen] = useState(false);
   const [labs, setLabs] = useState([]);

   const [input, setInput] = useState('');
   const classes = useStyles();

   const login = e => {
      e.preventDefault();
      
      const re_swipenum = /^[0-9]{15,18}$/

      //If user entered a swipenum, convert
      if (input.match(re_swipenum)){
         Backend.convertSwipeNumToUID(input)
         .then(res_convert => {
            Backend.getUser(res_convert.UID)
            .then(res_getuser => {
               //Check if UID from swipenum is in db. If it is, proceed to machine selection page.
               //If it isn't, proceed to registerform
               if (res_getuser.status === 200 && res_convert.status === 200) {
                  onSubmit(res_getuser);
                  typeof routeFunc === 'function' && history.push('/user');
               } else {
                  onSubmit({status: res_convert.status, id: res_convert.UID});
                  typeof routeFunc === 'function' && history.push('/register');
               }
            })
         });

      //If user entered a UID, proceed as before. If UID entered was invalid, do not autofill.
      } else {
        //  Backend.getUser(input)
        //  .then(res => {
        //     onSubmit(res.status === 200? res : {status: res.status, id: input});
        //     typeof routeFunc === 'function' && history.push(routeFunc(res));
        //  });

        Promise.all([
            Backend.getUser(input), 
            Backend.getLabPermissionsByUser(input)
        ]).then(function (responses) {
            var user_res = responses[0];
            var lab_permission_res = responses[1];

            onSubmit(user_res.status === 200? user_res : {status: user_res.status, id: input});
            typeof routeFunc === 'function' && history.push(routeFunc(user_res, lab_permission_res));

        })

        

      }
      setCurrLab(currLab);
   };

   useEffect(() => {
      if (labs.length === 0){
         Backend.getLabs(1000)
         .then(res => {
            setLabs((res && res.items) || []);
            if (!currLab) {
               setCurrLab(res.items.length > 0 && res.items[0]);
            }
         });
      }
   } , [currLab, labs, setCurrLab]);//bug when labs is not initialized in time 


   const currLabName = (currLab && currLab.name) || 'Loading';
   return (
      <>
      <Drawer 
         open={settingsOpen} 
         onClose={() => setSettingsOpen(false)}
         anchor='right'
      >
         <Box
            width='250px'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            height='100%'
            p='1rem'
         >
            <FormControl className={classes.formControl}>
               <InputLabel shrink id="demo-simple-select-placeholder-label-label">
               Current Lab
               </InputLabel>
               <Select
                  value={currLab}
                  onChange={e => setCurrLab(e.target.value)}
                  displayEmpty
                  className={classes.selectEmpty}
               >
               <MenuItem value={currLab}>
                  <em>{currLabName}</em>
               </MenuItem>
               {labs.map(lab => 
                  <MenuItem 
                     key={lab.name}
                     value={lab}
                  >
                     {lab.name}
                  </MenuItem>
                  )}
               </Select>
            </FormControl>
         </Box>
      </Drawer>
      <Box
         display='flex'
         justifyContent='flex-end'
         p='1rem'
      >
         <IconButton aria-label="settings"
            onClick={() => setSettingsOpen(!settingsOpen)}
         >
            <SettingsIcon fontSize='large' style={{fontSize: '50px'}}/>
         </IconButton>
      </Box>
      <Box
         display='flex'
         flexDirection='column'
         justifyContent='center'
         height='60%'
      >
      <Grid container 
         justify='center'
      >
         <Grid item xs={12}> 
            <Typography variant='h2' align='center'>{currLabName}</Typography>
         </Grid>
         <Grid item xs={6} align='center'>
            <form onSubmit={login}>
            <TextField
               type='password'
               autoComplete='off'
               size='medium'
               autoFocus={true}
               variant='filled'
               fullWidth
               margin='normal'
               inputProps={{style: {
                  textAlign: 'center'
               }}}

               placeholder='Swipe id or enter UID to start!'
               value={input}
               onChange={e => {
                  const numMatch = e.target.value.match(/\d*/);
                  numMatch && setInput(numMatch[0]);
               }}
            />
            </form>
         </Grid>
      </Grid>
      </Box>
      </>
   );
}
Login.propTypes = {
   onSubmit: PropTypes.func
}

export default withRouter(Login)